import { ChevronRightIcon } from "@chakra-ui/icons";
import { Box, Button, chakra, Container, Flex, Image, Text, VStack } from "@chakra-ui/react";
import Footer from "@/components/Footer";
import Navbar from "@/components/Navbar";
import PageInfo from "@/components/PageInfo";
import { LinkButton } from "@/components/shared/generic";

const Link404 = ({ bg, href, children }) => (
  <LinkButton
    href={href}
    variant="unstyled"
    color="#000"
    py={2}
    fontSize={{ base: "xl", md: "2xl" }}
    borderRadius="2xl"
    bg={bg}
    size="xl"
  >
    {children}
  </LinkButton>
);

const Error404 = () => {
  return (
    <>
      <PageInfo
        title="404"
        themeColor="green.50"
        description="Don't worry—we have a history of getting people back on track."
      />

      <Box bg="linear-gradient(to right, #29051B, #89047F)" color="#fff" pb={200}>
        <Navbar inverted />

        <Container py={10} maxW="container.xl" pos="relative">
          <Flex flexDir={{ base: "column-reverse", md: "row" }} gap={10}>
            <Box flex={5}>
              <chakra.h1 mt={10} textStyle="pageHeader">
                Uh-oh! Page is not found.
              </chakra.h1>

              <Text fontSize="4xl" fontWeight="bold">
                Don&apos;t worry. We have a history of getting people back on track.
              </Text>

              <Flex
                flexDir={{ base: "column", md: "column" }}
                mt={4}
                gap={2}
                justify="space-evenly"
              >
                <Link404 bg="#FEFFFF" href="/profiles">
                  👩‍🎓 Find someone at your dream school
                </Link404>

                <Link404 bg="#F9D9F1" href="/plans">
                  🚀 Check out limited offers just for you
                </Link404>

                <Link404 bg="#F2A5D9" href="/get-started/upload-your-applications">
                  🎓 Get paid to share your applications
                </Link404>
              </Flex>
            </Box>

            <Box flex={4} zIndex="1" mt={12}>
              <Image src="/images/404/dog.png" alt="Dog" boxSize="100%" />
            </Box>
          </Flex>

          <Box
            pos="absolute"
            top="0"
            left="0"
            width="40px"
            height="40px"
            bg="rgba(255,255,255, .25)"
            borderRadius="50%"
          />

          <Box
            pos="absolute"
            top="4%"
            right="10%"
            width="20px"
            height="20px"
            bg="rgba(255,255,255, .25)"
            borderRadius="50%"
          />

          <Box
            pos="absolute"
            top="60%"
            right="36%"
            width="70px"
            height="70px"
            bg="rgba(255,255,255, .25)"
            borderRadius="50%"
          />

          <Box
            pos="absolute"
            bottom="0"
            left="20%"
            width="20px"
            height="20px"
            bg="rgba(255,255,255, .25)"
            borderRadius="50%"
          />

          <Box
            pos="absolute"
            top="80px"
            right="20px"
            width="400px"
            height="400px"
            bg="rgba(255,255,255, .25)"
            borderRadius="50%"
            zIndex="0"
          />
        </Container>
      </Box>

      <Box marginTop={{ base: -100, md: -170 }}>
        <Footer />
      </Box>
    </>
  );
};

export default Error404;
